/*!
 *
 *  Web Starter Kit
 *  Copyright 2015 Google Inc. All rights reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License
 *
 */
/* eslint-env browser */
(function() {
  'use strict';

  // Check to make sure service workers are supported in the current browser,
  // and that the current page is accessed from a secure origin. Using a
  // service worker from an insecure origin will trigger JS console errors. See
  // http://www.chromium.org/Home/chromium-security/prefer-secure-origins-for-powerful-new-features
  var isLocalhost = Boolean(window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
    );

  if ('serviceWorker' in navigator &&
      (window.location.protocol === 'https:' || isLocalhost)) {
    navigator.serviceWorker.register('service-worker.js')
    .then(function(registration) {
      // updatefound is fired if service-worker.js changes.
      registration.onupdatefound = function() {
        // updatefound is also fired the very first time the SW is installed,
        // and there's no need to prompt for a reload at that point.
        // So check here to see if the page is already controlled,
        // i.e. whether there's an existing service worker.
        if (navigator.serviceWorker.controller) {
          // The updatefound event implies that registration.installing is set:
          // https://slightlyoff.github.io/ServiceWorker/spec/service_worker/index.html#service-worker-container-updatefound-event
          var installingWorker = registration.installing;

          installingWorker.onstatechange = function() {
            switch (installingWorker.state) {
              case 'installed':
                // At this point, the old content will have been purged and the
                // fresh content will have been added to the cache.
                // It's the perfect time to display a "New content is
                // available; please refresh." message in the page's interface.
                break;

              case 'redundant':
                throw new Error('The installing ' +
                                'service worker became redundant.');

              default:
                // Ignore
            }
          };
        }
      };
    }).catch(function(e) {
      console.error('Error during service worker registration:', e);
    });
  }

  // Your custom JavaScript goes here

  // teammember click 
  // OPEN
  $(".teamBTNopen").on('click', function(e){
    e.preventDefault();
    $('#pauker').removeClass('open');
    $('#kahnt').removeClass('open');
    $(this).closest('.teammember').addClass('open');
    var name = $(this).data('name');
    if(name != ''){
      $('.subteam').removeClass('pauker').removeClass('kahnt');      
      $('.subteam').addClass(name);
    }
  });
  //CLOSE
  $(".teamBTNclose").on('click', function(e){
    e.preventDefault();
    $(this).closest('.teammember').removeClass('open');
    var name = $(this).data('name');
    if(name != ''){
      $('.subteam').removeClass('pauker').removeClass('kahnt');
    }
  });


  $('.withsub').on('mouseenter', function(e){
    if($(document).width() > 960){
      $(this).removeClass('closed');
      $(this).addClass('open');
    }
  });
  $('.withsub').on('mouseleave', function(e){
    if($(document).width() > 960){
      $(this).removeClass('open');
      $(this).addClass('closed');
    }
  });

  $('.arrow').on('click', function(e){
    $(this).closest('.withsub').toggleClass('open closed');
  });

  $('.mobileBTN').on('click', function(e){
    e.preventDefault();
    $('nav').toggleClass('open closed');
  });
  $('a[href*="#"]').on('click', function(){
    $('nav').removeClass('open').addClass('closed');
  });
  $('.mobileArrow').on('click', function(){
    $(this).closest('.withsub').find('subnav').toggleClass('open closed');
  });

    $( window ).on( "load", function() { 
      //simple scroll script
      // Select all links with hashes
      $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
          // On-page links
          if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
            && 
            location.hostname == this.hostname
          ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
              // Only prevent default if animation is actually gonna happen
              event.preventDefault();
              $('html, body').animate({
                scrollTop: target.offset().top
              }, 1000, function() {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                  return false;
                } else {
                  //$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                  $target.focus(); // Set focus again
                };
              });
            }
          }
        });
    });

    //scroll function
    $(document).on('scroll', function(){
      if($(this).scrollTop() > $('header').height()){
        $('html').addClass('onscroll');
      } else {
        $('html').removeClass('onscroll');
      }
    });

    //codes
    $(document).ready(function(){
      var phonLog = $('.phonLog');
      var phoneEdu = $('.phoneEdu');

      $('a.mailLog').attr('href', function(){
          var username = "info";
          var hostname = "pauker-logopaedie.de";
          var linktext = username + "@" + hostname ;
          return 'mailto:' + linktext;
      }).text(function(){
          var username = "info";
          var hostname = "pauker-logopaedie.de";
          var linktext = username + "@" + hostname ;
          return linktext;
      });

      $('a.phoneLog').attr('href', function(){
          var username = "821";
          var hostname = "2197694";
          var linktext = "0049" + username + hostname ;
          return 'tel:' + linktext;
      }).text(function(){
          var username = "0821";
          var hostname = "2197694";
          var linktext = username + "-" + hostname ;
          return linktext;
      });
      $('a.mailEdu').attr('href', function(){
          var username = "coach";
          var hostname = "pauker-bildung.de";
          var linktext = username + "@" + hostname ;
          return 'mailto:' + linktext;
      }).text(function(){
          var username = "coach";
          var hostname = "pauker-bildung.de";
          var linktext = username + "@" + hostname ;
          return linktext;
      });

      $('a.phoneEdu').attr('href', function(){
          var username = "171";
          var hostname = "3421748";
          var linktext = "0049" + username + hostname ;
          return 'tel:' + linktext;
      }).text(function(){
          var username = "0171";
          var hostname = "3421748";
          var linktext = username + "-" + hostname ;
          return linktext;
      });

    });
})();
